<template>
  <div class="bg-white w-full overflow-auto h-screen">
    <div class="m-auto p-5 md:w-3/5 md:mt-10">
      <div class="">
        <Logo :path="logo" width="100" className="flex justify-center"></Logo>
      </div>
      <StepProgressBar :steps="3" :stepSelected="2" class="mt-8" />
      <h4 class="mt-8 text-xl font-bold">PAYMENT METHOD</h4>
      <hr />
      <CheckoutForm />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import CheckoutForm from "@/components/checkout/CheckoutForm.vue";
import StepProgressBar from "@/components/general/StepProgressBar.vue";
import Logo from "@/components/auth/Logo";

const logo = ref(require("@/assets/icons/mm-icon.svg"));
</script>
